@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Roboto:wght@300;400&display=swap");

/* trick pour obfusquer tant que possible l'email de contact */
.e-mail:before {
  content: "\0068\0065\006c\006c\006f\0040\0072\0061\006c\006c\0079\0065\0068\0069\0076\0065\0072\002e\0066\0072";
  font-weight: bold;
  font-size: 1.2em;
}

html { font-size: 62.5%; }

body {
	margin: 0;
	padding: 0;
  background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), 
		#000 url("./res/tristan-colangelo-d4OnMIl5OYk-unsplash.jpg") center/cover no-repeat fixed;
  font-family: 'Abril Fatface', sans-serif;
	text-shadow: 1px 1px 7px #000;
  font-size: 1rem;
	width: 70%;
	max-width: 700px;
	margin: 10vh auto 60px auto;
	text-align: left;
} 

section {
  margin-top: 3rem;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 2rem;
  color: #fff;
  line-height: 1.3;
}

nav { margin-top: 3rem; }

h1, h2 { 
  font-size: 5.2rem; 
  font-weight: normal;
  font-style: normal;
  margin: 10px 0;
}

h1 { color: #ffffff; }
h2 { color: #fff000; }

a { 
  text-decoration: none;
  color: #fff;
}

button, input[type=submit] { 
  border: none;
  padding: 14px 18px;
  margin-right: 20px;
  min-width: 180px;
  font-size: 1.8rem;
  font-family: "Abril Fatface", cursive, sans-serif;
  background-color: #fff000;
  color: #000;
  cursor: pointer;
  border-radius: 5px;
  -webkit-appearance: none;
  appearance: none;
}
button:hover, input[type=submit]:hover { box-shadow: 0 0 5px #fff000; }
button svg { margin-right: 10px; }

.disabled-button {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}
.disabled-button:hover { box-shadow: none; }

.editions-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 2rem;
}

.year-edition {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 10px #000;
  background-color: #eee;
  color: #000;
  font-weight: 400;
  text-shadow: none;
}
div.year-edition {
  opacity: 0.6;
}
a.year-edition:hover {
  box-shadow: 0 0 5px #ccc;
  background-color: #fefff1;
}

.year-edition > * {
  display: inline-block;
  vertical-align: middle;
}

.year-edition img {
  width: 100px;
  border-radius: 100px;
  margin-right: 20px;
  border: 1px solid #000;
}






form { margin-top: 3rem; }

input {
  padding: 14px 18px;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 1px 1px 7px #333;
  vertical-align: top;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 5px;
  font-size: 1.8rem;
  color: #000;
}

input[name="teamName"]{
  width: 100%;
  margin-bottom: 3rem;
}

.teamLeader, .teamMember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.teamLeader input, .teamMember input {
  flex-basis: 49%;
}

.teamMember input[name="name"] { flex-basis:46% }
  
#deleteTeamMember {
  min-width: auto;
  background: none;
  color: #fff000;
  font-family: sans-serif;
  padding: 0;
  vertical-align: top;
  margin: 0;
  font-size: 1.4rem;

}

#errorMessage {
	color: black;
  background-color: red;
  padding: 1rem;
  text-shadow: none;
}

#processing { text-align: center; margin-top: 35vh; }

@media (orientation: portrait) {

  body {
    font-size: 1rem;
    width: 85%;
    margin: 3rem auto;
    text-align: left;
  }

  h1 { font-size: 4.6rem; }
  h2 { font-size: 4rem; }

  button { 
    width: 100%;
    padding: 12px 15px;
    margin: 10px 0;
  }

}

@import url(./pacman.css);